define("virtual-each/components/virtual-each/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G8l1a3B7",
    "block": "{\"symbols\":[\"item\",\"idx\",\"&inverse\",\"&default\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"infinite-list-content\"],[12,\"style\",[23,\"contentStyle\"]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"visibleItems\"]]],[[\"key\"],[\"@index\"]],{\"statements\":[[0,\"  \"],[7,\"li\"],[12,\"class\",[30,[\"virtual-item visible-item-\",[24,2,[]]]]],[9],[0,\"\\n    \"],[15,4,[[24,1,[\"raw\"]],[24,1,[\"actualIndex\"]],[24,1,[\"virtualIndex\"]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},{\"statements\":[[0,\"  \"],[7,\"li\"],[11,\"class\",\"virtual-list-empty\"],[9],[0,\"\\n    \"],[15,3],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "virtual-each/components/virtual-each/template.hbs"
    }
  });

  _exports.default = _default;
});