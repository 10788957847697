define("ember-drag-drop/components/draggable-object-target", ["exports", "ember-drag-drop/mixins/droppable", "ember-jquery-legacy"], function (_exports, _droppable, _emberJqueryLegacy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_droppable.default, {
    classNameBindings: ['overrideClass'],
    overrideClass: 'draggable-object-target',
    isOver: false,
    handlePayload: function handlePayload(payload, event) {
      var obj = this.get('coordinator').getObject(payload, {
        target: this
      });
      this.get('action')(obj, {
        target: this,
        event: event
      });
    },
    handleDrop: function handleDrop(event) {
      var dataTransfer = event.dataTransfer;
      var payload = dataTransfer.getData("Text");

      if (payload === '') {
        return;
      }

      this.handlePayload(payload, event);
    },
    acceptDrop: function acceptDrop(event) {
      this.handleDrop(event); //Firefox is navigating to a url on drop sometimes, this prevents that from happening

      event.preventDefault();
    },
    handleDragOver: function handleDragOver(event) {
      if (!this.get('isOver')) {
        //only send once per hover event
        this.set('isOver', true);

        if (this.get('dragOverAction')) {
          this.get('dragOverAction')(event);
        }
      }
    },
    handleDragOut: function handleDragOut(event) {
      this.set('isOver', false);

      if (this.get('dragOutAction')) {
        this.get('dragOutAction')(event);
      }
    },
    click: function click(e) {
      var onClick = this.get('onClick');

      if (onClick) {
        onClick((0, _emberJqueryLegacy.normalizeEvent)(e));
      }
    },
    mouseDown: function mouseDown(e) {
      var mouseDown = this.get('onMouseDown');

      if (mouseDown) {
        mouseDown((0, _emberJqueryLegacy.normalizeEvent)(e));
      }
    },
    mouseEnter: function mouseEnter(e) {
      var mouseEnter = this.get('onMouseEnter');

      if (mouseEnter) {
        mouseEnter((0, _emberJqueryLegacy.normalizeEvent)(e));
      }
    },
    actions: {
      acceptForDrop: function acceptForDrop() {
        var hashId = this.get('coordinator.clickedId');
        this.handlePayload(hashId);
      }
    }
  });

  _exports.default = _default;
});