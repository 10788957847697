define('ember-bootstrap-changeset-validations/components/bs-form/element', ['exports', 'ember-bootstrap/components/bs-form/element'], function (exports, _element) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _element.default.extend({

    hasValidator: Ember.computed.notEmpty('model.validate'),

    setupValidations: function setupValidations() {
      var key = 'model.error.' + this.get('property') + '.validation';
      Ember.defineProperty(this, 'errors', Ember.computed(key + '[]', function () {
        return Ember.A(this.get(key));
      }));
    }
  });
});