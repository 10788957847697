define("fimab-2d-view/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "fimab-2d-view/util", "fimab-2d-view/math"], function (_exports, _defineProperty2, _util, _math) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.transformComponentForExport = _exports.restrictYOnSurface = _exports.restrictXOnSurface = _exports.restrictW = _exports.restrictRounding = _exports.restrictR = _exports.restrictH = _exports.restrictDecimal = _exports.restrictComponentValue = _exports.restrictCenterYOnSurface = _exports.restrictCenterXOnSurface = _exports.newComponentId = _exports.exportComponent = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  /**
   * Erzeugt eine eindeutige Komponenten ID
   */
  var newComponentId = function newComponentId() {
    return String('c' + String(new Date().getTime()).substr(-5) + (Math.random() * 10000 >> 0));
  };
  /**
   * Liefert einen validen x-Wert der Komponente auf der angegebenen Schrankoberfläche zurück
   *
   * @param surface
   * @param value
   * @param instance
   */


  _exports.newComponentId = newComponentId;

  var restrictXOnSurface = function restrictXOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);

    if (value - bBox.left < surface.minX) {
      return surface.minX + bBox.left;
    } else if (value + bBox.right > surface.maxX) {
      return surface.maxX - bBox.right;
    } else {
      return value;
    }
  };

  _exports.restrictXOnSurface = restrictXOnSurface;

  var restrictCenterXOnSurface = function restrictCenterXOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);

    if (value - bBox.left < surface.minX + instance.w / 2) {
      return surface.minX + bBox.left + instance.w / 2;
    } else if (value + bBox.right > surface.maxX + instance.w / 2) {
      return surface.maxX - bBox.right + instance.w / 2;
    } else {
      return value;
    }
  };
  /**
   * Liefert einen validen y-Wert der Komponente auf der angegebenen Schrankoberfläche zurück
   *
   * @param surface
   * @param value
   * @param instance
   */


  _exports.restrictCenterXOnSurface = restrictCenterXOnSurface;

  var restrictYOnSurface = function restrictYOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);

    if (surface.minY > value - bBox.bottom) {
      return surface.minY + bBox.bottom;
    } else if (value + bBox.top > surface.maxY) {
      return surface.maxY - bBox.top;
    } else {
      return value;
    }
  };

  _exports.restrictYOnSurface = restrictYOnSurface;

  var restrictCenterYOnSurface = function restrictCenterYOnSurface(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);

    if (surface.minY + instance.h / 2 > value - bBox.bottom) {
      return surface.minY + bBox.bottom + instance.h / 2;
    } else if (value + bBox.top > surface.maxY + instance.h / 2) {
      return surface.maxY - bBox.top + instance.h / 2;
    } else {
      return value;
    }
  };

  _exports.restrictCenterYOnSurface = restrictCenterYOnSurface;

  var restrictR = function restrictR(value) {
    return value;
  };
  /**
   * Liefert eine valide Dezimalstelle auf 1 gerundet
   *
   * @param value
   * @param instance
   */


  _exports.restrictR = restrictR;

  var restrictDecimal = function restrictDecimal(value) {
    if (isNaN(value)) {// return 0;
    }

    if (typeof value !== 'string') {
      value = value.toString();
    }

    value = value.replace(",", ".");

    if (value.indexOf(".") > 0) {
      var _temp = value.split(".");

      var dec = _temp[1].substring(0, 2);

      value = _temp[0] + "." + dec;
    } // return Math.round((input.replace(',', '.') * 100 ) /100);


    return value;
  };
  /**
   * Liefert eine valide Breite der Komponente zurück
   *
   * @param value
   * @param instance
   */


  _exports.restrictDecimal = restrictDecimal;

  var restrictW = function restrictW(value, instance) {
    if (instance.minW && value < instance.minW) {
      return instance.minW;
    } else if (instance.maxW && value > instance.maxW) {
      return instance.maxW;
    } else {
      return value;
    }
  };
  /**
   * Liefert eine valide Höhe der Komponente zurück
   *
   * @param value
   * @param instance
   */


  _exports.restrictW = restrictW;

  var restrictH = function restrictH(value, instance) {
    if (instance.minH && value < instance.minH) {
      return instance.minH;
    } else if (instance.maxH && value > instance.maxH) {
      return instance.maxH;
    } else {
      return value;
    }
  };
  /**
   * Liefert einen validen Wert für die Rundung der Komponente zurück
   *
   * @param value
   * @param instance
   */


  _exports.restrictH = restrictH;

  var restrictRounding = function restrictRounding(surface, value, instance) {
    var bBox = (0, _math.boundingBox)(instance);

    if (value < instance.minRounding || 0) {
      return instance.minRounding || 0;
    } else if (instance.maxRounding && value > instance.maxRounding) {
      return instance.maxRounding;
    } else {
      return value;
    }
  };
  /**
   * Liefert für die übergebene Property der Komponente einen erlaubten Wert zurück
   *
   * @param cabinetSurface
   * @param instance
   * @param key
   * @param value
   */


  _exports.restrictRounding = restrictRounding;

  var restrictComponentValue = function restrictComponentValue(cabinetSurface, instance, key, value) {
    var newValue = (0, _util.sanitizeUserInput)(value, instance[key]);

    if (key === 'x') {
      newValue = restrictXOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    } else if (key === 'y') {
      newValue = restrictYOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    } else if (key === 'r') {
      newValue = restrictR(newValue);
    } else if (key === 'w') {
      newValue = restrictW(newValue, instance);
      newValue = restrictDecimal(newValue);
    } else if (key === 'h') {
      newValue = restrictH(newValue, instance);
      newValue = restrictDecimal(newValue);
    } else if (key === 'rounding') {
      newValue = restrictRounding(cabinetSurface, newValue, instance); // newValue = restrictDecimal(newValue);
    } else if (key === 'centerX') {
      newValue = restrictCenterXOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    } else if (key === 'centerY') {
      newValue = restrictCenterYOnSurface(cabinetSurface, newValue, instance);
      newValue = (0, _math.round)(newValue);
    }

    return newValue;
  };

  _exports.restrictComponentValue = restrictComponentValue;

  var transformComponentForExport = function transformComponentForExport(component, surface) {
    return exportComponent(component, surface);
  };

  _exports.transformComponentForExport = transformComponentForExport;

  var exportComponent = function exportComponent(component, surface) {
    return _objectSpread(_objectSpread({}, component), {}, {
      x: component.centerX,
      y: component.centerY,
      d: 40
    });
  };

  _exports.exportComponent = exportComponent;
});