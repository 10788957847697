define("fimab-2d-view/components/fimab-unityview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u5WWq1U1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"webgl-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"unityContainer\"],[11,\"style\",\"width: 960px; height: 600px\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fullscreen\"],[11,\"onclick\",\"unityInstance.SetFullscreen(1)\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "fimab-2d-view/components/fimab-unityview/template.hbs"
    }
  });

  _exports.default = _default;
});