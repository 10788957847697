define("liquid-fire/templates/version-specific/get-outlet-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "78sUIuCw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"-get-dynamic-var\",[\"outletState\"],null]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "liquid-fire/templates/version-specific/get-outlet-state.hbs"
    }
  });

  _exports.default = _default;
});