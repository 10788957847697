define('ember-bootstrap-changeset-validations/components/bs-form', ['exports', 'ember-bootstrap/components/bs-form'], function (exports, _bsForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bsForm.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this._initValidation();
    },


    hasValidator: Ember.computed.notEmpty('model.validate'),

    validate: function validate(model) {
      var m = model;

      (false && !(m && typeof m.validate === 'function') && Ember.assert('Model must be a Changeset instance', m && typeof m.validate === 'function'));

      return m.get('isValid') ? Ember.RSVP.resolve() : Ember.RSVP.reject();
    },


    _initValidation: Ember.observer('model', function () {
      if (this.get('hasValidator')) {
        this.get('model').validate();
      }
    })
  });
});