define("fimab-2d-view/math", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/defineProperty", "fimab-2d-view/types"], function (_exports, _toConsumableArray2, _defineProperty2, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortSnapCandidates = _exports.round = _exports.rotateRect = _exports.pointIntersects = _exports.normalizeRect = _exports.isContained = _exports.intersectsRectNotRotated = _exports.intersects = _exports.intersectRects = _exports.getComponentAlignments = _exports.createAlignHelpers = _exports.checkAlignment = _exports.boundingBox = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  /**
   * Liefert 'true', falls rectSrc vollständig in rectTarget enthalten ist.
   *
   * @param rectSrc
   * @param rectTarget
   */
  var isContained = function isContained(rectSrc, rectTarget) {
    return rectSrc.x >= rectTarget.x && rectSrc.x + rectSrc.w <= rectTarget.x + rectTarget.w && rectSrc.y >= rectTarget.y && rectSrc.y + rectSrc.h <= rectTarget.y + rectTarget.h;
  };
  /**
   * Liefert 'true', falls die beiden Rect Parameter eine Schnittmenge besitzen.
   *
   * @param src
   * @param target
   */


  _exports.isContained = isContained;

  var intersects = function intersects(src, target) {
    if (!!!src.r && !!!target.r) {
      return intersectsRectNotRotated(src, target);
    } else {
      return intersectRects(src, target);
    }
  };
  /**
   * Liefert true, falls sich das src und target Rect schneiden. Beide Rect Objekte dürfen NICHT
   * rotiert sein!
   *
   * @param src
   * @param target
   */


  _exports.intersects = intersects;

  var intersectsRectNotRotated = function intersectsRectNotRotated(src, target) {
    //console.time('intersectsRectNotRotated');
    if (src.r || target.r) {
      throw 'src und target Rect duerfen nicht rotiert sein';
    }

    var _src = normalizeRect(src);

    var _target = normalizeRect(target);

    var intersects = _src.x < _target.x + _target.w && _src.x + _src.w > _target.x && _src.y < _target.y + _target.h && _src.y + _src.h > _target.y; //console.timeEnd('intersectsRectNotRotated');

    return intersects;
  };
  /**
   * Liefert true, falls der angegebene Point innerhalb des durch targetPoints beschriebenen Polygons
   * liegt
   *
   * @param point
   * @param targetPoints
   */


  _exports.intersectsRectNotRotated = intersectsRectNotRotated;

  var pointIntersects = function pointIntersects(point, targetPoints) {
    // Punkt-in-Polygon-Test nach Jordan
    var x = point.x;
    var y = point.y;
    var inside = false;

    for (var i = 0, j = targetPoints.length - 1; i < targetPoints.length; j = i++) {
      var xi = targetPoints[i].x;
      var yi = targetPoints[i].y;
      var xj = targetPoints[j].x;
      var yj = targetPoints[j].y;
      var intersect = yi > y != yj > y && x < (xj - xi) * (y - yi) / (yj - yi) + xi;

      if (intersect) {
        inside = !inside;
      }
    }

    return inside;
  };
  /**
   * Liefert true, falls sich das src und target Rect schneiden. Beide Rect Objekte dürfen rotiert sein.
   * Zur Ermittlung der Überschneidung werden alle 8 Punkte beider Rects verwendet: wenn einer dieser
   * Points eine der Linien des jeweils anderen Rect schneidet, überlappen die Rects.
   *
   * @param src
   * @param target
   */


  _exports.pointIntersects = pointIntersects;

  var intersectRects = function intersectRects(src, target) {
    //console.time('intersectRects');
    var srcRect = rotateRect(src);
    var targetRect = rotateRect(target);
    var srcPoints = [{
      x: srcRect.x1,
      y: srcRect.y1
    }, {
      x: srcRect.x2,
      y: srcRect.y2
    }, {
      x: srcRect.x3,
      y: srcRect.y3
    }, {
      x: srcRect.x4,
      y: srcRect.y4
    }];
    var targetPoints = [{
      x: targetRect.x1,
      y: targetRect.y1
    }, {
      x: targetRect.x2,
      y: targetRect.y2
    }, {
      x: targetRect.x3,
      y: targetRect.y3
    }, {
      x: targetRect.x4,
      y: targetRect.y4
    }];
    var intersect = false;

    for (var _i = 0, _srcPoints = srcPoints; _i < _srcPoints.length; _i++) {
      var srcPoint = _srcPoints[_i];
      intersect = pointIntersects(srcPoint, targetPoints) || intersect;
    }

    for (var _i2 = 0, _targetPoints = targetPoints; _i2 < _targetPoints.length; _i2++) {
      var targetPoint = _targetPoints[_i2];
      intersect = pointIntersects(targetPoint, srcPoints) || intersect;
    } //console.timeEnd('intersectRects');


    return intersect;
  };
  /**
   * Liefert ein normalisiertes Rect zurück, d.h. mit positiver Breite und Höhe
   *
   * @param rect
   */


  _exports.intersectRects = intersectRects;

  var normalizeRect = function normalizeRect(rect) {
    var _rect = _objectSpread({}, rect);

    _rect = _rect.w > 0 ? _rect : _objectSpread(_objectSpread({}, _rect), {}, {
      x: _rect.x + _rect.w,
      w: -_rect.w
    });
    _rect = _rect.h > 0 ? _rect : _objectSpread(_objectSpread({}, _rect), {}, {
      y: _rect.y + _rect.h,
      h: -_rect.h
    });
    return _rect;
  };
  /**
   * Ermittelt mögliche Alignments des Source und Target Rect. Insgesamt werden 14 Möglichkeiten
   * überprüft:
   * Horizontale und vertikale Mitte beide Elemente, 2x horizontale Mitte Source zu Ober- und
   * Unterkante Target, 2x vertikale Mitte Source zu linke und rechte Kante Target, alle Kombinationen
   * linke, rechte, obere und untere Source und Target (8x).
   *
   * @param src
   * @param target
   * @param origin
   * @param around
   */


  _exports.normalizeRect = normalizeRect;

  var checkAlignment = function checkAlignment(src, target, origin) {
    var around = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    //console.time('checkAlignment');
    var alignments = [];
    var delta;
    var srcBBox = boundingBox(src);
    var targetBBox = boundingBox(target); // Src Bottom, Target Bottom

    delta = srcBBox.minY - targetBBox.minY;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Top,
        delta: delta,
        x1: Math.min(srcBBox.minX, targetBBox.minX),
        y1: targetBBox.minY,
        x2: Math.max(srcBBox.maxX, targetBBox.maxX),
        y2: targetBBox.minY,
        targetValue: targetBBox.minY + srcBBox.bottom
      });
    } // Src Top, Target Bottom


    delta = srcBBox.maxY - targetBBox.minY;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Top,
        delta: delta,
        x1: Math.min(srcBBox.minX, targetBBox.minX),
        y1: targetBBox.minY,
        x2: Math.max(srcBBox.maxX, targetBBox.maxX),
        y2: targetBBox.minY,
        targetValue: targetBBox.minY - srcBBox.top
      });
    } // Src Bottom, Target Top


    delta = srcBBox.minY - targetBBox.maxY;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Bottom,
        delta: delta,
        x1: Math.min(srcBBox.minX, targetBBox.minX),
        y1: targetBBox.maxY,
        x2: Math.max(srcBBox.maxX, targetBBox.maxX),
        y2: targetBBox.maxY,
        targetValue: targetBBox.maxY + srcBBox.bottom
      });
    } // Src Top, Target Top


    delta = srcBBox.maxY - targetBBox.maxY;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Bottom,
        delta: delta,
        x1: Math.min(srcBBox.minX, targetBBox.minX),
        y1: targetBBox.maxY,
        x2: Math.max(srcBBox.maxX, targetBBox.maxX),
        y2: targetBBox.maxY,
        targetValue: targetBBox.maxY - srcBBox.top
      });
    } // Src Left, Target Right


    delta = srcBBox.minX - targetBBox.maxX;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Right,
        delta: delta,
        x1: targetBBox.maxX,
        y1: Math.min(srcBBox.minY, targetBBox.minY),
        x2: targetBBox.maxX,
        y2: Math.max(srcBBox.maxY, targetBBox.maxY),
        targetValue: targetBBox.maxX + srcBBox.left
      });
    } // Src Right, Target Right


    delta = srcBBox.maxX - targetBBox.maxX;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Right,
        delta: delta,
        x1: targetBBox.maxX,
        y1: Math.min(srcBBox.minY, targetBBox.minY),
        x2: targetBBox.maxX,
        y2: Math.max(srcBBox.maxY, targetBBox.maxY),
        targetValue: targetBBox.maxX - srcBBox.right
      });
    } // Src Left, Target Left


    delta = srcBBox.minX - targetBBox.minX;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Left,
        delta: delta,
        x1: targetBBox.minX,
        y1: Math.min(srcBBox.minY, targetBBox.minY),
        x2: targetBBox.minX,
        y2: Math.max(srcBBox.maxY, targetBBox.maxY),
        targetValue: targetBBox.minX + srcBBox.left
      });
    } // Src Right, Target Left


    delta = srcBBox.maxX - targetBBox.minX;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.Left,
        delta: delta,
        x1: targetBBox.minX,
        y1: Math.min(srcBBox.minY, targetBBox.minY),
        x2: targetBBox.minX,
        y2: Math.max(srcBBox.maxY, targetBBox.maxY),
        targetValue: targetBBox.minX - srcBBox.right
      });
    } // Src, Target VCenter
    // [TBD] Berechnung sieht scheisse aus. Geht es einfacher?


    delta = srcBBox.minX + (srcBBox.maxX - srcBBox.minX) / 2 - (targetBBox.minX + (targetBBox.maxX - targetBBox.minX) / 2);

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.VerticalCenter,
        delta: delta,
        x1: targetBBox.minX + (targetBBox.maxX - targetBBox.minX) / 2,
        y1: Math.min(srcBBox.minY, targetBBox.minY),
        x2: targetBBox.minX + (targetBBox.maxX - targetBBox.minX) / 2,
        y2: Math.max(srcBBox.maxY, targetBBox.maxY),
        targetValue: target.x + target.w / 2 - src.w / 2 //(targetBBox.minX + (targetBBox.maxX - targetBBox.minX) / 2) - ((srcBBox.maxX - srcBBox.minX) / 2) + srcBBox.left

      });
    } // Src, Target HCenter


    delta = srcBBox.minY + (srcBBox.maxY - srcBBox.minY) / 2 - (targetBBox.minY + (targetBBox.maxY - targetBBox.minY) / 2);

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.HorizontalCenter,
        delta: delta,
        x1: Math.min(srcBBox.minX, targetBBox.minX),
        y1: target.y + Math.round(target.h / 2),
        x2: Math.max(srcBBox.maxX, targetBBox.maxX),
        y2: target.y + Math.round(target.h / 2),
        targetValue: target.y + target.h / 2 - src.h / 2
      });
    } // Src HCenter, Target Bottom


    delta = srcBBox.minY + (srcBBox.maxY - srcBBox.minY) / 2 - targetBBox.minY;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.HorizontalCenter,
        delta: delta,
        x1: Math.min(srcBBox.minX, targetBBox.minX),
        y1: targetBBox.minY,
        x2: Math.max(srcBBox.maxX, targetBBox.maxX),
        y2: targetBBox.minY,
        targetValue: targetBBox.minY - src.h / 2
      });
    } // Src HCenter, Target Top


    delta = srcBBox.minY + (srcBBox.maxY - srcBBox.minY) / 2 - targetBBox.maxY;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.HorizontalCenter,
        delta: delta,
        x1: Math.min(srcBBox.minX, targetBBox.minX),
        y1: targetBBox.maxY,
        x2: Math.max(srcBBox.maxX, targetBBox.maxX),
        y2: targetBBox.maxY,
        targetValue: targetBBox.maxY - src.h / 2
      });
    } // Src VCenter, Target Left


    delta = srcBBox.minX + (srcBBox.maxX - srcBBox.minX) / 2 - targetBBox.minX;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.VerticalCenter,
        delta: delta,
        x1: targetBBox.minX,
        y1: Math.min(srcBBox.minY, targetBBox.minY),
        x2: targetBBox.minX,
        y2: Math.max(srcBBox.maxY, targetBBox.maxY),
        targetValue: targetBBox.minX - src.w / 2
      });
    } // Src VCenter, Target Right


    delta = srcBBox.minX + (srcBBox.maxX - srcBBox.minX) / 2 - targetBBox.maxX;

    if (Math.abs(delta) <= around) {
      alignments.push({
        type: _types.AlignmentTypes.VerticalCenter,
        delta: delta,
        x1: targetBBox.maxX,
        y1: Math.min(srcBBox.minY, targetBBox.minY),
        x2: targetBBox.maxX,
        y2: Math.max(srcBBox.maxY, targetBBox.maxY),
        targetValue: targetBBox.maxX - src.w / 2
      });
    } //console.timeEnd('checkAlignment');


    return alignments;
  };

  _exports.checkAlignment = checkAlignment;

  var round = function round(value) {
    var step = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.1;
    step || (step = 1.0);
    var inv = 1.0 / step;
    return Math.round(value * inv) / inv;
  };
  /**
   * Ermittelt die nächstbeste Koordinate für den nächsten Drop. Die nächstbeste Koordinate ist jeweils der x/y Wert mit
   * dem geringsten Abstand zum aktuellen x/y.
   *
   * @param alignments
   * @param pos
   */


  _exports.round = round;

  var sortSnapCandidates = function sortSnapCandidates(alignments, pos) {
    var newX = pos.x;
    var newY = pos.y;

    if (alignments.length) {
      var bestX = [];
      var bestY = [];
      alignments.forEach(function (alignment) {
        if (alignment.type === _types.AlignmentTypes.Right || alignment.type === _types.AlignmentTypes.Left || alignment.type === _types.AlignmentTypes.VerticalCenter) {
          bestX.push([alignment.targetValue, alignment.delta]);
        }

        if (alignment.type === _types.AlignmentTypes.Top || alignment.type === _types.AlignmentTypes.Bottom || alignment.type === _types.AlignmentTypes.HorizontalCenter) {
          bestY.push([alignment.targetValue, alignment.delta]);
        }
      });

      var sortDeltafct = function sortDeltafct(a, b) {
        return a[1] < b[1] ? 1 : a[1] > b[1] ? -1 : 0;
      };

      if (bestX.length) {
        bestX = bestX.sort(sortDeltafct);
        newX = bestX[0][0];
      }

      if (bestY.length) {
        bestY = bestY.sort(sortDeltafct);
        newY = bestY[0][0];
      }
    }

    return {
      x: newX,
      y: newY
    };
  };
  /**
   * Liefert die Alignments des angegebenen Source Rect zu den Rects in others. Der around Parameter
   * bestimmt den minimalen Abstand den Source und Target haben müssen, damit ein Alignment
   * möglich ist.
   *
   * @param rect Nächstes Rect Source Komponente
   * @param others Liste von Target Rects
   * @param origin Aktueller Ursprung Source Komponente
   * @param around Snapping Distanz
   */


  _exports.sortSnapCandidates = sortSnapCandidates;

  var getComponentAlignments = function getComponentAlignments(rect, others, origin) {
    var around = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
    var alignments = [];
    others.forEach(function (targetRect) {
      var a = checkAlignment(rect, targetRect, origin, around);
      alignments = [].concat((0, _toConsumableArray2.default)(alignments), (0, _toConsumableArray2.default)(a));
    });
    return alignments;
  };
  /*
  export const sizeInBounds = (surface: CabinetSurfaceConfiguration, instance: ComponentInstance): { w: number, h: number } => {
    const maxX = Math.min(
      Math.min(instance.w + instance.x, surface.maxX - instance.x),
      instance.maxW || surface.maxX
    );
    const maxY = Math.min(
      Math.min(instance.h + instance.y, surface.maxY - instance.y),
      instance.maxH || surface.maxY
    );
    return {
      w: Math.max(maxX, instance.minW || 0),
      h: Math.max(maxY, instance.minH || 0)
    }
  } */

  /**
   * Wandelt einen Gradwert in entsprechenden Radianten um. Dreht gegen den Uhrzeigersinn!
   *
   * @param deg
   */


  _exports.getComponentAlignments = getComponentAlignments;

  var d2r = function d2r(deg) {
    return deg * (Math.PI / 180) * -1;
  };
  /**
   * Rotiert einen Punkt um deg Grad um den Ursprung 0,0
   *
   * @param p
   * @param deg
   */


  var rotatePoint = function rotatePoint(p, deg) {
    if (deg === 0 || deg % 360 === 0) {
      return {
        x: p.x,
        y: p.y
      };
    } else {
      return {
        x: p.x * Math.cos(d2r(deg)) + p.y * Math.sin(d2r(deg)),
        y: -p.x * Math.sin(d2r(deg)) + p.y * Math.cos(d2r(deg))
      };
    }
  };
  /**
   * Rotiert das angegebene Rect und liefert ein Rect2D Object mit den einzelnen rotierten Punkten
   *
   * @param rect
   */


  var rotateRect = function rotateRect(rect) {
    var deg = rect.r;
    var hw = rect.w / 2;
    var hh = rect.h / 2;
    var r_tl = rotatePoint({
      x: -hw,
      y: hh
    }, deg);
    var r_tr = rotatePoint({
      x: hw,
      y: hh
    }, deg);
    var r_br = rotatePoint({
      x: hw,
      y: -hh
    }, deg);
    var r_bl = rotatePoint({
      x: -hw,
      y: -hh
    }, deg);
    var centerX = rect.x + hw;
    var centerY = rect.y + hh;
    var r = {
      x1: centerX + r_tl.x,
      y1: centerY + r_tl.y,
      x2: centerX + r_tr.x,
      y2: centerY + r_tr.y,
      x3: centerX + r_br.x,
      y3: centerY + r_br.y,
      x4: centerX + r_bl.x,
      y4: centerY + r_bl.y
    };
    return r;
  };
  /**
   * Liefert die Bounding Box des übergebenen Rect, d.h. das umschliessende Rect unter
   * Berücksichtigung der Rotation.
   * @param rect
   */


  _exports.rotateRect = rotateRect;

  var boundingBox = function boundingBox(rect) {
    var rrect = rotateRect(rect);
    var xs = [rrect.x1, rrect.x2, rrect.x3, rrect.x4];
    var ys = [rrect.y1, rrect.y2, rrect.y3, rrect.y4];
    var minX = xs.slice(1).reduce(function (v, acc) {
      return v < acc ? v : acc;
    }, xs[0]);
    var maxX = xs.slice(1).reduce(function (v, acc) {
      return v > acc ? v : acc;
    }, xs[0]);
    var minY = ys.slice(1).reduce(function (v, acc) {
      return v < acc ? v : acc;
    }, ys[0]);
    var maxY = ys.slice(1).reduce(function (v, acc) {
      return v > acc ? v : acc;
    }, ys[0]);
    return {
      minX: minX,
      minY: minY,
      maxX: maxX,
      maxY: maxY,
      left: rect.x - minX,
      bottom: rect.y - minY,
      right: maxX - rect.x,
      top: maxY - rect.y
    };
  };
  /**
   * Erzeugt anhand der übergebenen Schrankoberfläche ein Array von Rects, die nicht gerendert, aber
   * für die Berechnung möglicher Alignments von Komponenten verwendet wird.
   *
   * @param surface
   * @param width
   * @param height
   */


  _exports.boundingBox = boundingBox;

  var createAlignHelpers = function createAlignHelpers(surface, width, height) {
    var alignHelpers;

    if (surface.lockArea) {
      alignHelpers = [{
        x: surface.minX,
        y: height,
        w: surface.lockArea.x - surface.minX,
        h: 1,
        r: 0
      }, {
        x: surface.minX,
        y: 0,
        w: surface.lockArea.x - surface.minX,
        h: 1,
        r: 0
      }, {
        x: surface.lockArea.x + surface.lockArea.w,
        y: height,
        w: surface.maxX - (surface.lockArea.x + surface.lockArea.w),
        h: 1,
        r: 0
      }, {
        x: surface.lockArea.x + surface.lockArea.w,
        y: 0,
        w: surface.maxX - (surface.lockArea.x + surface.lockArea.w),
        h: 1,
        r: 0
      }, {
        x: 0,
        y: 0,
        w: 1,
        h: height,
        r: 0
      }, {
        x: width,
        y: 0,
        w: 1,
        h: height,
        r: 0
      }];
    } else {
      alignHelpers = [{
        x: surface.minX,
        y: height,
        w: surface.maxX - surface.minX,
        h: 1,
        r: 0
      }, {
        x: surface.minX,
        y: 0,
        w: surface.maxX - surface.minX,
        h: 1,
        r: 0
      }, {
        x: 0,
        y: 0,
        w: 1,
        h: height,
        r: 0
      }, {
        x: width,
        y: 0,
        w: 1,
        h: height,
        r: 0
      }];
    }

    return alignHelpers;
  };

  _exports.createAlignHelpers = createAlignHelpers;
});