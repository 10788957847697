define("ember-bootstrap/templates/components/bs-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I81hqdUA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"element\",\"group\",\"isSubmitting\",\"isSubmitted\",\"isRejected\",\"resetSubmissionState\"],[[29,\"component\",[[25,[\"elementComponent\"]]],[[\"model\",\"formLayout\",\"horizontalLabelGridClass\",\"showAllValidations\",\"disabled\",\"readonly\",\"onChange\",\"_onChange\"],[[25,[\"model\"]],[25,[\"formLayout\"]],[25,[\"horizontalLabelGridClass\"]],[25,[\"showAllValidations\"]],[24,0,[\"disabled\"]],[24,0,[\"readonly\"]],[29,\"action\",[[24,0,[]],\"change\"],null],[29,\"action\",[[24,0,[]],\"resetSubmissionState\"],null]]]],[29,\"component\",[[25,[\"groupComponent\"]]],[[\"formLayout\"],[[25,[\"formLayout\"]]]]],[25,[\"isSubmitting\"]],[25,[\"isSubmitted\"]],[25,[\"isRejected\"]],[29,\"action\",[[24,0,[]],\"resetSubmissionState\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bootstrap/templates/components/bs-form.hbs"
    }
  });

  _exports.default = _default;
});